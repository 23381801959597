$(function() {

	var App = (function() {

		function destroyLinks() {

			// Destroy links
			$('a.destroy').on('click', function(event) {
				event.preventDefault();
				$(this).next('form.form-destroy').submit();
			});

		}

		function selectFilters() {

			// Go to URL after selecting an option in a select field
			$('#filter-by-doctor, #filter-by-year').bind('change', function () {
				var url = $(this).val();

				if (url) {
					window.location = url;
				}

				return false;
			});

		}

		function datepicker() {
			var currentUri = new URI(window.location.href);
			var year = URI.parseQuery(currentUri.query()).year;

			var datepickerOptions = {
				format: 'YYYY-MM-DD',
				viewMode: 'days',
				daysOfWeekDisabled: [0, 6],
				widgetPositioning: {
					horizontal: 'left',
					vertical: 'auto'
				 }
			};

			if (year) {
				datepickerOptions.minDate = moment(year).startOf('year');
				datepickerOptions.maxDate = moment(year).endOf('year');
				datepickerOptions.useCurrent = false;
			}

			// Init datepickers
			$('.datepicker').datetimepicker(datepickerOptions);
		}

		function printButtons() {
			$('.btn-print').on('click', function(e) {
				e.preventDefault();
				window.print();
			});
		}

		function printCalendar() {
			var data;

			if (typeof calendarData !== 'undefined') {
				data = $.parseJSON(calendarData);

				for (index = 0; index <= data.months.length; ++index) {
					$('.clndr-print-' + (index  + 1) ).clndr({
						template: $('#printCalendarTemplate').html(),
						showAdjacentMonths: false,
						forceSixRows: true,
						startWithMonth: data.months[index],
						events: data.days
					});
				}

				window.print();

			}
		}

		return {
			init : function() {
				destroyLinks();
				selectFilters();
				datepicker();
				printButtons();
				printCalendar();
			}
		};

	})();

	App.init();

});
