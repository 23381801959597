moment.locale('pl');

clndr = angular.module('clndrApp', ['tien.clndr']);

clndr.service('dataService', function ($http) {
	var urlBase = '/api/v1';

	this.getStay = function (stay_id) {
		return $http.get(urlBase + '/stays/' + stay_id);
	};

	this.toggleDayStatus = function (stay_id, date) {
		return $http.post(urlBase + '/stays/'  + stay_id + '/toggle/' + date);
	};

	this.toggleMonthStatus = function (stay_id, date) {
		return $http.post(urlBase + '/stays/'  + stay_id + '/toggle-month/' + date);
	};
});

clndr.service('dayService', function () {
	this.isValid = function(day) {
		var date = day.date;

		// holiday or day on the weekend?
		if ((date.weekday() == 5) || (date.weekday() == 6)
			|| (day.events[0] && day.events[0].status == 'holiday')) {

			return false;

		}

		return true;
	}

	this.formatDate = function(date) {
		return date.format('YYYY-MM-DD');
	}

	this.formatMonth = function(date) {
		return date.format('YYYY-MM');
	}
});

clndr.controller('clndrCtrl', function ($scope, $http, $log, dataService, dayService) {
	dataService.getStay($scope.stay_id).
		success(function(data, status, headers, config) {

			$scope.stay = data;
			$scope.events = data.days;
			$scope.mandaysCount = data.mandaysCount;

			// clndr options + events
			$scope.options = {
				showAdjacentMonths: false
			};

			// actual stay?
			if ($scope.stay.end !== null) {
				$scope.options.startWithMonth = moment($scope.stay.start);
			} else {
				$scope.options.startWithMonth = moment();
			}

			// tell directive that data is loaded
			$scope.calendarDataLoaded = true;

		}).
		error(function(data, status, headers, config) {
			$log.error('getStay - error talking to API: ' + status);
		});

	$scope.toggleDayStatus = function(day) {

		if (!dayService.isValid(day)) return;

		if ($scope.stay.end !== null) {
			return alert('Kalendarz pobytu w Księdze Głównej nie może być modyfikowany.');
		}

		dataService.toggleDayStatus($scope.stay_id, dayService.formatDate(day.date)).
			success(function(data, status, headers, config) {

				$scope.stay = data;
				$scope.events = data.days;
				$scope.mandaysCount = data.mandaysCount;

			}).
			error(function(data, status, headers, config) {
				$log.error('toggleDayStatus - error talking to API: ' + status);
			});

	};

	$scope.toggleMonthStatus = function(month) {

		if ($scope.stay.end !== null) {
			return alert('Kalendarz pobytu w Księdze Głównej nie może być modyfikowany.');
		}

		dataService.toggleMonthStatus($scope.stay_id, dayService.formatDate(month)).
			success(function(data, status, headers, config) {

				$scope.stay = data;
				$scope.events = data.days;
				$scope.mandaysCount = data.mandaysCount;

			}).
			error(function(data, status, headers, config) {
				$log.error('toggleMonthStatus - error talking to API: ' + status);
			});

	};

	$scope.goBack = function(clndr) {
		if (clndr.month > moment($scope.stay.start, "YYYY-MM-DD")) {
			clndr.back();
		}
	}

	$scope.goForward = function(clndr) {
		if (clndr.month < moment($scope.stay.start, "YYYY-MM-DD").add(6, 'months')) {
			clndr.forward();
		}
	}

	$scope.showMandaysCount = function(month) {
		month = dayService.formatMonth(month);
		return $scope.mandaysCount[month];
	}
});
